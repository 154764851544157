.unauthorized {
    height: 600px;
    width: 100%;
    color: red;
    display: flex;

    div {
        margin: auto;
    }
}


div.section {
    margin-bottom: 30px;
    opacity: 1;

    &:first-of-type {
        margin-top: 30px;
    }

    &.hidden {
        opacity: 0;
    }

    &:not(.hidden) {
        animation-name: fadeIn;
        animation-duration: 100ms;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.float-right {
    float: right;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Amazon Ember', sans-serif;
    background-color: #f0f0f0;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

header {
    border: 1px solid #000;
    padding: 20px;
    //margin-bottom: 20px;
    background-color: #c9fffe;
    text-align: center;
    /* Center align content */
}

// nav ul {
//     list-style-type: none;
//     margin: 0;
//     padding: 0;
// }

// nav li {
//     display: inline;
//     margin-right: 10px;
// }

// nav a {
//     text-decoration: none;
//     color: #000;
//     padding: 5px 10px;
// }

// nav li:not(:last-child)::after {
//     content: "|";
//     margin: 0 5px;
//     color:#000;
// }

// nav a:hover {
//     color: #fff;
// }

.logo,
.app-title-h1 {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding-bottom: 3%;
}

#acs-header .app-title-h1 {
    font-size: 85px;
    font-weight: 100;
    font-style: italic;
}

.logo img {
    width: 85px;
    height: 85px;
}

#user-info {
    width: 30%;
    height: 100%;
    padding: inherit;
    text-align: left;
}

#user-info>h2 {
    font: 1em sans-serif;
}

.section-with-bar {
    position: relative;
}

.bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #c9fffe;
    /* Choose your desired color */
}

.bar>h2 {
    font-size: 1rem;
}

section {
    border: 1px solid #000;
    padding-bottom: 60px;
    padding-top: 3rem;
    margin-bottom: 20px;
    background-color: #fff;
    min-height: 300px;
    position: relative;
    text-align: center;
    /* Center align content */
}

#metrics {
    height: 100%;
    padding: 55px 0 1% 0;
}

.admin-info {
    display: inline-flex;
}

.admin-info-item {
    padding: 0 2rem;

}

.filter-input {
    width: 50%;
    height: 2rem;
    margin: 0 auto;
}

.table-container {
    max-height: 275px;
    overflow-y: auto;
}

.table-container table {
    border-collapse: collapse;
    width: 100%;
    user-select: none;
    cursor: default;
}

.table-container th,
.table-container td {
    border: 1px solid #000;
    text-align: center;
}

.table-container th {
    position: sticky;
    top: 0;
    background-color: #f2f2f2;
}

.table-container th::after:not(:last-child) {
    content: "|";
    margin-left: 5px;
    /* Add space between header labels and vertical line */
}

.table-container tr:focus {
    background-color: #c9fffe;
}

.risk-header-text {
    display: inline;
    margin: 0;
}

.risk-table {
    display: inline-table;
    width: 100%;
    text-align: left;
}

.new-risk-category-container {
    display: flex;
    justify-content: end;
    padding: 10px;
}

.risk-table-header {
    display: flex;
    justify-content: space-between;
    margin: 12px;

    .button-row {
        display: flex;
        justify-items: flex-end;

        button {
            margin-right: 10px;
        }
    }
}

.buttons-container {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: row;
}

/* Style the buttons */
.buttons-container button {
    display: block;
    /* Ensure buttons stack vertically */
    margin-top: 5px;
    /* Add some space between buttons */
}

/* Additional styling for buttons (optional) */
.buttons-container button {
    padding: 8px 16px;
    /* Add padding */
    background-color: #fff;
    /* Button background color */
    color: #000;
    /* Button text color */
    border: solid 1px #000;
    /* Remove button border */
    cursor: pointer;
    /* Add cursor on hover */
}

/* Style for button hover effect (optional) */
.buttons-container button:hover {
    background-color: #c9fffe;
    /* Darken button color on hover */
}

.side-by-side {
    display: flex;
}

.side-section {
    width: 50%;
}

#control-overview {
    margin-right: 10px
}

#incident-overview {
    margin-left: 10px
}

.metric-box {
    border: 1px solid #000;
    padding: 1%;
    background-color: #fff;
    width: 20%;
    height: 50px;
    display: inline-flex;
    justify-content: center;
    /* Align buttons to the right */
}

.metric-box>.title .mcontent {
    display: flex;
    flex-direction: column;
}

.modal-wrapper {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(107, 114, 128, 0.5);
    z-index: 9;
    width: 100%;
}

.modal {
    position: relative;
    background-color: #fff;
    width: 50%;
    height: 80%;
    border: 1px solid rgb(179, 179, 179);
    border-radius: 10px 10px 0 10px;
    margin: 20px;
    overflow: hidden;
}

.modal .body {
    max-height: calc(100% - 60px);
    overflow-y: scroll;
}

.modal .body .modal-body {
    padding: 20px;
}

.modal .admin-section {
    border: solid 1px #000;
    padding: 20px;
    margin-bottom: 2%;
}


.entity-additional-info {
    margin: 20px 0;
    padding: 20px;
}

.additional-fields {
    display: none;
}

.additional-fields.active {
    display: block;
}

.date-range {
    display: inline-flex;
    width: 100%;
}

.date-range-item {
    justify-content: flex-start;
    margin-right: 3%;
    width: 25%;
}

.date-range-item input {
    width: 100%;
    height: 30px;
}

.textbox-wrapper {
    display: flex;
    width: 100%;
}

.textbox-item {
    width: 50%;
}

.textbox-item textarea {
    width: 80%;
    height: 120px;
}

.modal .footer {
    width: 100%;
    height: 60px;
    background-color: rgb(243, 244, 246);
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
}

.modal .footer button {
    border-style: solid 1px #000;
    background: #fff;
    padding: 10px;
    color: #000;
    line-height: 1.25rem;
    float: right;
    cursor: pointer;
}

.modal .footer button:hover {
    background-color: #c9fffe;
}

label {
    margin-right: 10px;
}

.radio-group-container {
    margin-bottom: 10px;
}

.radio-group-label {
    display: inline-block;
    margin-left: 10px;
    width: 250px;

}

#welcome span {
    font-weight: bold;

}

.heading-with-aside {
    display: flex;
    justify-content: space-between;

    button {
        height: 3rem;
    }
}

.space-above {
    margin-top: 10px;
}

.grow-to-fit {
    display: flex;
}

.center-content {
    display: flex;
    height: 300px;
    justify-content: center;
    align-items: center;
}


.flex {
    display: flex !important;
    justify-content: space-evenly;
}

.left-col {
    flex-grow: 1;
    max-width: 650px;
}

.right-col {
    padding: 12px;
    flex-grow: 2;
    margin-left: 20px;
}

.edit-incident {

    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .associate-risk-with-incident {
        margin-bottom: 10px;

        label {
            display: inline-block;
            width: 100px;
        }
    }

    .incident-sub-risk-association {
        list-style: none;
        padding-left: 5px;
    }

    .spread-apart {

        line-height: 2rem;

        .sub_risk_name_and_primary_label {
            display: inline-block;
            width: 600px;
        }

        span.sub-risk-name {
            display: inline-block;
            margin-right: 10px;
        }

        span.is-primary {
            display: inline-block;
            width: 80px;
        }

        span.button-holder {
            width: 100px;
            display: inline-block;
        }
    }

    .primary-risk {
        font-weight: bold;
        font-style: italic;
        text-decoration: underline;
    }
}

section.page-section {
    padding: 4rem 4.5rem;

    .page-header-title {
        padding-left: 0;
    }
}

.poseiden {
    color: #005276 !important;
}

/* AWS UI Overrides */

.awsui_layout_5gtk3_1iuve_105.awsui_is-visual-refresh_5gtk3_1iuve_105>.awsui_background_5gtk3_1iuve_108:not(#\9),
div.awsui_background_hyvsj_17k0d_99>.awsui_scrolling-background_hyvsj_17k0d_102:not(#\9) {
    visibility: hidden;
}

.awsui_drawer-content_1uo6m_117ru_129 {
    position: relative !important;
    height: 100%;
}

.awsui_root_14iqq_1exfv_185 {
    margin-bottom: 10px;
}

/* div[role="radiogroup"] {
    display: inline-flex !important;
    align-items: baseline;
} */

.awsui_root_14iqq_ft8qw_103 {
    margin-bottom: 10px;
}

.awsui_scrolling-background_hyvsj_m8ieb_148 {
    background-color: inherit !important;
}

.awsui_layout_hyvsj_m8ieb_388 {
    background-color: #E1F5FE !important;
}

.awsui_logo_k5dlb_1jsho_262 {
    max-block-size: 60px !important;
}

.awsui_title_k5dlb_1jsho_274 {
    line-height: 32px !important;
    font-size: 24px !important;
}

.entity-table-site-name {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.site-tab-content {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fake-map {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    width: 95%;
    height: 600px;
    background-color: darkseagreen;
}

.site-landing-page {
    .entity-additional-info {
        padding: 0;
    }

    .admin-section,
    .entity-info,
    .title {
        margin-bottom: 8px;
    }
}

.mr-6 {
    margin-right: 6px;
}

.mt-6 {
    margin-top: 6px;
}


.radio-fieldset label {
    vertical-align: middle;
}

.control-details-modal {
    ul {
        list-style-type: "- ";
        margin: 0 0 0 10px;
        padding: 0;

        li.inactive {
            color: #999;
        }
    }
}

ul.no-list-style {
    list-style-type: none;
    margin-top: 0;
    margin-bottom: 30px;
    padding: 0;
}

h3.center {
    text-align: center;
}

.table-cell-background {
    padding: 5px;
    width: 100%;
    text-wrap: nowrap;
    border-radius: 10px;
    text-align: center;
}

.word-break {
    word-break: break-word;
    overflow-wrap: break-word;
}

.muted {
    color: #999;
}